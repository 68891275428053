/**
 * Created by TomasDePomas on 26-1-16.
 */

$("#menu-toggle").click(function(e) {
    e.preventDefault();
    $("#wrapper").toggleClass("toggled");
});

$("[data-tab-switch]").click(function(e){
    e.preventDefault();
    $this = $(this);

    $('[data-tab-switch].active').removeClass('active');
    $('[data-tab-content]').hide();


    $('[data-tab-content]' + $this.attr('data-tab-switch')).show();
    $this.addClass('active');
});